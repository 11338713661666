body {
    font-family: "bely";
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
    font-size: 18px;
    overflow-x: hidden;
}

p {
    font-family: "Sofia Pro", Sans-serif;
}

a {
    color: #008080;
    text-decoration: none;
}

a:hover {
    color: #20C997 !important;
    text-decoration: none;
}

.banner-heading-main {
    color: #000 !important;
    font-weight: 600;
}

.banner-heading {
    color: #fff !important;
    font-weight: 600;
}

h1 {
    font-family: "Sofia Pro", Sans-serif;
    font-size: 64px;
    line-height: 1.1em;
    color: #008080 !important;
}

h2 {
    color: #074767;
    font-family: "Sofia Pro", Sans-serif;
    font-size: 40px;
    line-height: 1.25em;
    color: #008080 !important;
}

h3 {
    font-size: 32px;
    line-height: 1.25em;
    color: #008080 !important;
}

h4 {
    font-size: 24px;
    line-height: 1.25em;
    color: #008080 !important;
}

h5 {
    font-size: 20px;
    line-height: 1.25em;
    color: #008080 !important;
}

h6 {
    font-size: 16px;
    line-height: 1.25em;
    color: #008080 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-block-start: 0.5rem;
    margin-block-end: 1rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
}

.logo {
    width: 100%;
    max-width: 250px;
    min-height: 70px;
}

@media only screen and (max-width:400px) {
    .logo {
        max-width: 190px;
    }
}

@media only screen and (max-width:1500px) {
    .phone-header {
        display: none;
    }
}

img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

section {
    padding: 80px 0px !important;
}

.main-head {
    font-size: 50px;
}

.color-main {
    color: #008080;
}

.color-secondary {
    color: #20c997;
}

.color-main {
    color: #008080;
}

#main-banner {
    /* background-image: url("../../img/banner/main-banners.jpg"); */
    background-size: 100% 100%;
    min-height: 728px;
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 1500px) {
    #main-banner {
        min-height: 900px;
    }
}

@media only screen and (max-width: 1024px) {
    #main-banner {
        background-position: center right;
    }
}

#about-us-banner {
    background-image: url("../img/banner/blue-rectangle.svg");
    background-size: cover;
    width: 80%;
    background: #20C997;
}

.secondary_banner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 170px 0 150px 0;
    position: relative;
}

.secondary_banner::before {
    background: linear-gradient(-90deg, transparent, #0c3246 100%);
}

.background-overlay {
    background: radial-gradient(at top center, rgba(0, 128, 128, 0.61) 0, #008080 100%);
}

.about-us-banner-two {
    background-image: url("../img/images/service-banner.jpeg");
}

header {
    max-width: none;
}

@media only screen and (min-width: 576px) {
    .nav-margin {
        top: 15px;
        left: 15px;
        right: 15px
    }
}

/* Buttons */
.btn-main {
    font-family: "Sofia Pro", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #333;
    background-color: #20C997;
    border-color: #20C997;
    border-radius: 10px 10px 10px 10px;
    padding: 12px 24px;
}

.btn-primary {
    font-family: "Sofia Pro", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    padding: 6px 12px;
}

.countdown-icons img {
    max-height: 100px;
}

@media (min-width: 1500px) {
    .navbar-expand-xl .navbar-nav {
        gap: 20px;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav {
        gap: 20px;
    }
}

@media (max-width: 1200px) {
    .navbar-nav .nav-item {
        padding: 10px;
    }
}

.btn-secondary {
    font-family: "Sofia Pro", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #ffffff;
    background-color: #008080;
    border-color: #008080;
    border-radius: 10px 10px 10px 10px;
    padding: 6px 12px;
}

.btn-dark {
    font-family: "Sofia Pro", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #ffffff;
    background-color: #000;
    border-color: #000;
    border-radius: 10px 10px 10px 10px;
    padding: 12px 24px;
}

.btn-dark:hover {
    background-color: #0000008f !important;
    border-color: #0000008f !important;
}

.btn-sm-secondary {
    font-family: "Sofia Pro", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #ffffff;
    background-color: #008080;
    border-color: #008080;
    border-radius: 10px 10px 10px 10px;
    padding: 6px 12px;
}

button:hover {
    background-color: #42DACC !important;
    border-color: #42DACC !important;
    color: #fff !important;
}


@media only screen and (min-width: 768px) {
    .img_text {
        top: 33%
    }
}

#main_div_img_text {
    position: relative;
    top: 26%;
    margin-top: auto;
}

@media only screen and (max-width: 500px) {
    #main_div_img_text_bottom {
        position: relative;
        top: 10%;
        margin-top: none;
    }

    .main_div_img_text {
        display: none !important;
    }

    #main-banner {
        background-image: none;
        min-height: 400px;
    }
}

@media only screen and (max-width: 1024px) {
    .main_div_img_text {
        visibility: hidden;
    }

}

@media only screen and (min-width: 1024px) {
    .sec_main_div_img_text {
        display: none;
    }
}

@media only screen and (min-width: 1024px) {
    .sec_main_div_img_text {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    #header_contact_us {
        display: none;
    }
}


@media only screen and (min-width: 1200px) {
    #fixed_contact_us {
        display: none;
    }

}

.bg-custom-blue {
    background-color: #074767;
}

#Carousel_one .owl-prev {
    position: relative;
    right: 54%;
    bottom: 140px;
    font-size: 44px !important;
}

#Carousel_one .owl-next {
    position: relative;
    left: 54%;
    bottom: 140px;
    font-size: 44px !important;
}

#Carousel_one .owl-prev:hover {
    border-color: none;
    background-color: none !important;
    color: black;
}

#Carousel_one .owl-next:hover {
    border-color: none;
    background-color: none !important;
    color: black;
}

.navbar-nav .nav-item .nav-link {
    font-weight: 600;
    color: #000;
}

.dropdown-menu .dropdown-item {
    font-weight: 600;
    color: #333;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    border-radius: 0px;
    padding: 0px;
}

.dropdown-menu .dropdown-item {
    border-bottom: 1px solid #33333361;
}

.owl-carousel .owl-item img {
    display: block;
    width: 160px;
}

.item img {
    margin: auto;
}

#Carousel_sec .owl-prev {
    position: relative;
    right: 54%;
    bottom: 140px;
    font-size: 44px !important;
}

#Carousel_sec .owl-next {
    position: relative;
    left: 54%;
    bottom: 140px;
    font-size: 44px !important;
}

#Carousel_sec .owl-prev:hover {
    border-color: none;
    background-color: none !important;
    color: black;
}

#Carousel_sec .owl-next:hover {
    border-color: #f8f9fa;
    background-color: #f8f9fa !important;
    color: black;
}

#Carousel_third .owl-prev {
    position: relative;
    right: 62%;
    bottom: 160px;
    font-size: 80px !important;
    color: white;

}

.owl-nav {
    height: 0px;
}

#Carousel_third .owl-next {
    position: relative;
    left: 62%;
    bottom: 160px;
    font-size: 80px !important;
    color: white;
}

#Carousel_third .owl-prev:hover {
    border-color: none;
    background: none !important;
}

#Carousel_third .owl-next:hover {
    border-color: none;
    background: none !important;
}

#form1 {
    position: relative;
    top: 45px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 25px !important;
}

@media only screen and (min-width: 992px) {
    #Investing_In_Client_Relationships {
        margin-top: 180px !important;
    }
}

/* @media only screen and (min-width: 992px) { */
/* #Bookkeeping_Services_text {
        position: relative;
        top: 130px;
    } */
/* } */


@media only screen and (min-width: 767px) {
    .why_choose_us_img {
        position: relative;
        top: 50px;
        left: 85px;
        border-radius: 174px;
    }
}

.about-card .card-title {
    font-size: 32px;
    font-weight: 700;
    color: #e74c3c;
}

.about-card .card-body {
    padding: 32px;
}

/* Background Colors */
.bg-main {
    background: #20C997 !important;
}

.dropdown-menu .dropdown-item:hover {
    border-left: 5px solid #20C997;
    padding-left: 11px;
}

.testimonial-card {
    min-height: 300px;
}

.testimonial-card .author {
    font-weight: 600;
}

.bg-secondary {
    background: #008080 !important;
}

.text-main {
    color: #333333 !important;
}

.text-black {
    color: #000 !important;
}

.text-secondary {
    color: #20C997 !important;
}

.accordion-button:hover {
    background-color: #fff !important;
}

.accordion-button:not(.collapsed) {
    background-color: #fff !important;
}

.item a {
    color: inherit !important;
    text-decoration: none !important;
}

.swiper-wrapper {
    text-align: center;
}

.blog-card .blog-date {
    font-size: 1rem;
    color: #333;
}

.blog-card .card-title {
    font-size: 1.75rem;
    font-weight: 400;
}

.blog-card .card-text {
    font-size: 16px;
}

.blog-card .card-body {
    min-height: 368px;
}

.dropdown-item.active,
.dropdown-item:active {
    background: none;
}

.contact-us img {
    width: 50px;
    height: 50px;
}

.contact-us h3 {
    font-size: 26px;
}

.nav-NavLink {
    color: #000;
    font-weight: 600;
}

.nav-NavLink.active {
    color: #20c997
}

#exampleModal #form1 {
    border-radius: 0px !important;
}

@media only screen and (max-width:900px) {
    .popup-content {
        width: 90% !important;
    }
}

.form-control:focus {
    border-color: rgba(32, 201, 151, 0.25);
    box-shadow: 0 0 0 .25rem rgba(32, 201, 151, 0.25);
}

form-check-input:checked {
    background-color: #20c997;
    border-color: #20c997;
}

.form-check-input:focus {
    box-shadow: 0 0 0 .25rem rgba(32, 201, 151, 0.25);
}

.active>.page-link,
.page-link.active {
    border-color: #20c997;
    color: #20c996;
    background-color: #fff !important;
}

.active>.page-link:focus,
.page-link.active:focus {
    border-color: #20c997;
    color: #20c996;
    background-color: #fff !important;
}

.page-link {
    color: #20c996;
}

.form-check-input:checked {
    background-color: #20c997;
    border-color: #20c997;
}

.popup-content {
    top: 50px;
    width: 30%;
}

.form-control {
    line-height: 2rem;
}

.social-links svg {
    background: #20c997;
    color: #fff;
    padding: 10px;
    padding-right: 10px;
    margin-right: 5px;
}

.social-links svg:hover {
    color: #008989;
    background: #20c997;
    padding: 10px;
    padding-right: 10px;
    margin-right: 5px;
}

.footer-services ul {
    list-style: none;
    padding-left: 0px;
}

.footer-services ul a{
    color: var(--bs-body-color);
}

.rounded{
    border-radius: 0px 0px 0.375rem 0.375rem !important;
}

.dropdown-item{
    font-size: 18px;
}
.react-photo-album--image{
    width: auto !important;
}
.nav-pills{
    gap: 10px;
}
.nav-pills .nav-item .active {
    background: #20c997;
}